.header-container {
    padding: 16px 24px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .header-text {
    font-size: 1.2rem;
    font-weight: 500;
    color: #1a237e;
    cursor: pointer;
  }
  
  .logo-image {
    height: 40px;
    cursor: pointer;
  }
  
  .main-container {
    min-height: calc(100vh - 64px);
    padding: 14px;
    background-color: #f5f5f5;
  }
  
  .white-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    padding: 32px ;
    min-height: calc(100vh - 112px);
    width: 100%;
  }
  
  .page-title {
    margin-bottom: 24px;
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
    text-align: left;
  }
  
  .card-header {
    padding: 16px;
    border-radius: 4px 4px 0 0;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom: none;
  }
  
  .card-header-current {
    background-color: #0a3e69;
  }
  
  .card-header-upcoming {
    background-color: #f39702;
  }
  
  .header-text-white {
    color: white;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .card-content {
    padding: 24px 16px;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 0 0 4px 4px;
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Add margin to the first card group only */
  .card-content:first-of-type {
    margin-bottom: 16px;  /* Reduced space between cards */
  }
  
  .card-description {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .button-current, .button-upcoming {
    padding: 12px;
    font-size: 0.875rem;
    border-radius: 4px;
    color: white;
    text-transform: none;
    box-shadow: none;
    width: 100%;
  }
  
  .button-current {
    background-color: #0a3e69;
  }
  
  .button-current:hover {
    background-color: #0a3e69;
  }
  
  .button-upcoming {
    background-color: #f39702;
  }
  
  .button-upcoming:hover {
    background-color: #f39702;
  }
  
  @media (max-width: 600px) {
    .main-container {
      padding: 0;
      background-color: white;
    }
    
    .white-container {
      box-shadow: none;
      padding: 16px;
      min-height: calc(100vh - 64px);
    }
    
    .card-header,
    .card-content {
      width: 100%;
      margin-bottom: 12px;
    }
    
    .page-title {
      display: none;
    }
    
    .card-content:first-of-type {
      margin-bottom: 12px;  /* Even smaller gap for mobile */
    }
  }